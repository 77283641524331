import { createSlice } from "@reduxjs/toolkit";
import {
  createOrders,
  getPaymentList,
  getPricingPlan,
  verifyPayment,
} from "../actions/paytment.action";
import { updateUserAfterPayment } from "./auth.reducer";

export const pricingPlanSlice = createSlice({
  name: "priority",
  initialState: {
    pricingPlan: [],
    order: null,
    paymentList: [],
    loading: false,
    error: null,
  },
  reducers: {
    resetPricingState: (state) => {
      return {
        pricingPlan: [],
        loading: false,
        error: null,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPricingPlan.pending, (state, action) => {
        state.loading = true;
        state.pricingPlan = action.payload;
      })
      .addCase(getPricingPlan.fulfilled, (state, action) => {
        state.loading = false;
        state.pricingPlan = action.payload?.data;
        state.error = "";
      })
      .addCase(getPricingPlan.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });

    builder
      .addCase(getPaymentList.pending, (state, action) => {
        state.loading = true;
        state.paymentList = action.payload;
      })
      .addCase(getPaymentList.fulfilled, (state, action) => {
        state.loading = false;
        state.paymentList = action.payload?.result;
        state.error = "";
      })
      .addCase(getPaymentList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(createOrders.pending, (state) => {
        state.loading = true;
      })
      .addCase(createOrders.fulfilled, (state, action) => {
        state.loading = false;
        state.order = action.payload;
      })
      .addCase(createOrders.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { resetPricingState } = pricingPlanSlice.actions;
export default pricingPlanSlice.reducer;
