import styled from "styled-components";

export const OtpVerificationContainer = styled.div`
//   min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f0f2f5; /* Soft background */
  padding: 20px;

  .otp-card {
    background: #ffffff;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 40px;
    max-width: 450px;
    width: 100%;

    .otp-title {
      text-align: center;
      margin-bottom: 20px;
      color: #3f51b5;
    }
 
    .otp-form {
      margin-top: 20px;
      
      .otp-form-item {
        margin-bottom: 30px;
        align-items: center;
        display: flex;
      }

      .otp-input {
        width: 100%;
        text-align: center;
        font-size: 24px;
        letter-spacing: 10px;
        padding: 10px;
        border-radius: 8px;
        border: 1px solid #d9d9d9;
        box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
      }

      .verify-btn {
        width: 100%;
        background-color: #3f51b5;
        border-color: #3f51b5;
        border-radius: 6px;
        font-size: 16px;
      }

      .verify-btn:hover {
        background-color: #283593;
        border-color: #283593;
      }
    }

    .resend-text {
      display: block;
      text-align: center;
      margin-top: 20px;
    }

    .resend-text a {
      color: #3f51b5;
    }

    .resend-text a:hover {
      color: #283593;
    }
  }
`;
