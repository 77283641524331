import React, { useState } from "react";
import { Menu } from "antd";
import {
  FileDoneOutlined,
  LaptopOutlined,
  UsergroupAddOutlined,
  SettingOutlined,
  LogoutOutlined,
  DollarOutlined,
  QuestionCircleOutlined,
  HomeOutlined,
} from "@ant-design/icons";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { signOut } from "../../../redux/actions/auth.actions";
import "./Sidebar.css";

function Sidebar({ collapsed, onCollapse }) {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogout = async () => {
    localStorage.removeItem("userId");
    localStorage.removeItem("grantUser");
    localStorage.removeItem("userName");
    localStorage.removeItem("role");
    localStorage.removeItem("uuid");
    localStorage.removeItem("token");
    localStorage.removeItem("isPaidCustomer");
    localStorage.removeItem("resumeTemplate");
    localStorage.removeItem("resumeTemplateGroup");
    localStorage.removeItem("talentId");
    await dispatch(signOut());
    navigate("/signin");
  };

  const userRole = localStorage.getItem("role");

  function getItem(label, key, icon, children) {
    return {
      key,
      icon,
      children,
      label,
    };
  }

  const items = [
    userRole === "M" && getItem("Dashboard", "dashboard", <HomeOutlined />),
    userRole === "M" &&
      getItem("Talents", "talents", <UsergroupAddOutlined />, [
        getItem("New Talent", "new-talent"),
        getItem("Talent List", "talent-list"),
      ]),

    userRole !== "M" &&
      getItem("Resume", "resume", <FileDoneOutlined />, [
        getItem("Dashboard", "dashboard"),
        getItem("Update Resume", "profile"),
        getItem("Templates", "resume-templates"),
      ]),

    userRole !== "M" &&
      getItem("Jobs", "jobs", <LaptopOutlined />, [
        getItem("Applied Job List", "jobs-applied"),
        getItem("Referrals", "referrals"),
      ]),

    userRole !== "M" &&
      getItem("Transaction", "transaction", <DollarOutlined />, [
        getItem("Payment History", "payment-history"),
        getItem("Pricing", "pricing"),
      ]),

    getItem("Settings", "settings", <SettingOutlined />),
    userRole !== "M" &&
      getItem("Support", "support", <QuestionCircleOutlined />),
    getItem("Logout", "logout", <LogoutOutlined onClick={handleLogout} />),
  ].filter(Boolean);

  const [openKeys, setOpenKeys] = useState(["resume"]);

  const handleOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (["resume", "jobs", "transaction"].includes(latestOpenKey)) {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    } else {
      setOpenKeys(keys);
    }
  };

  const renderMenuItems = (menuItems) => {
    return menuItems.map((item) => {
      if (item.children) {
        return (
          <Menu.SubMenu key={item.key} icon={item.icon} title={item.label}>
            {renderMenuItems(item.children)}
          </Menu.SubMenu>
        );
      } else {
        return (
          <Menu.Item key={item.key} icon={item.icon}>
            <Link
              to={`/${item.key}`}
              onClick={item.key === "logout" ? handleLogout : null}
              style={{ textDecoration: "none", fontFamily: "Poppins" }}
            >
              {item.label}
            </Link>
          </Menu.Item>
        );
      }
    });
  };

  // Get the key of the currently active route
  const activeRouteKey = location.pathname.split("/")[1];

  return (
    <Menu
      theme="dark"
      mode="inline"
      inlineCollapsed={collapsed}
      defaultOpenKeys={["talents"]}
      selectedKeys={[activeRouteKey]}
      openKeys={openKeys}
      onOpenChange={handleOpenChange}
      onCollapse={onCollapse}
      className="sidebar-menu"
    >
      {renderMenuItems(items)}
    </Menu>
  );
}

export default Sidebar;
