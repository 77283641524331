import React, { Fragment } from "react";
import ViewTemplates from "../../../components/Dashboard/viewTemplates";
import { useSelector } from "react-redux";
import "../../../components/Dashboard/styles.css";

const ResumeTemplatesMain = () => {
  const currentUser = useSelector((state) => state.auth?.user?.userInfo);
  const talentId = localStorage.getItem("talentId");
  const resumeTemplate = localStorage.getItem("resumeTemplate"); 
   
  const showContent = talentId ? (
    <div>
      <h1
        className="home-head"
        style={{ marginBottom: "25px", marginTop: "40px", textAlign: "center" }}
      >
        Professional Resume Template for Talent
      </h1> 
      <ViewTemplates
        userId={talentId}
        userDatas={{ resume_template: resumeTemplate }}
      />
    </div>
  ) : (
    <div>
      <h1
        className="home-head"
        style={{ marginBottom: "25px", marginTop: "40px", textAlign: "center" }}
      >
        Professional Resume Template
      </h1> 
      <ViewTemplates
        userId={currentUser?.user_id}
        userDatas={currentUser}
      />
    </div>
  );

  console.log(currentUser?.user_id, currentUser, "currentUserccjjjxuuuu")
  return <Fragment>{showContent}</Fragment>;
};

export default ResumeTemplatesMain;
