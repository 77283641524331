import React, { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";

import NotFound from "./components/NotFound/index";
import { UnprotectedRouteLayout } from "./navigation/UnProtectedRouteLayout";
import { ProtectedRouteLayout } from "./navigation/ProtectedRouteLayout";
import MainLayout from "./layouts/MainLayout";
import SpinnerComponent from "./components/Temlpates/Spinner";
import "./App.css";
import PaymentListMain from "./pages/Transactions/paymentList";
import ResumeTemplatesMain from "./pages/Resumes/ResumeTemplates";
import DisableRightClick from "./components/Temlpates/DisableRightClick";
import OtpVerification from "./pages/Merchant/OtpVerification";
import EditTalentMain from "./pages/Merchant/EditTalent";

const DragAndDrop = lazy(() => import("./components/test/DragAndDrop"));
const Dashboard = lazy(() => import("./pages/Resumes/Dashboard"));
const Login = lazy(() => import("./pages/Login"));
const MerchantLogin = lazy(() => import("./pages/Login/merchantLogin"));
const RegisterMain = lazy(() =>
  import("./components/landing_page/pages/Register")
);
const AddTalentMain = lazy(() => import("./pages/Merchant/AddTalent"));
const TalentListMain = lazy(() => import("./pages/Merchant/TalentList"));
const ResumeBuilder = lazy(() => import("./pages/ProfileUpdates"));
const SelectTemplate = lazy(() => import("./pages/Resumes/SelectTemplate"));
const CvTemplateList = lazy(() => import("./pages/Resumes/CvTemplateList"));
const HomeMain = lazy(() =>
  import("./components/landing_page/pages/Home/index")
);
const AppliedJobs = lazy(() => import("./pages/Jobs/AppliedJobs"));
const PricingMain = lazy(() => import("./pages/Transactions/pricing"));
const PaymentDetailsMain = lazy(() =>
  import("./pages/Transactions/paymentDetails")
);
const JobDashboardMain = lazy(() => import("./pages/Jobs/JobDashboard"));
const LogoutPage = lazy(() =>
  import("./components/ReusableComponents/LogoutPage")
);
const SettingsMain = lazy(() => import("./pages/Settings"));
const SupportsMain = lazy(() => import("./pages/Supports"));
const UpdatePassword = lazy(() => import("./components/Login/UpdatePassword"));
const MerchantUpdatePassword = lazy(() =>
  import("./components/Login/MerchantUpdatePassword")
);
const ReferralsMain = lazy(() => import("./pages/Jobs/Referrals"));
const EmailVerification = lazy(() =>
  import("./components/landing_page/pages/EmailVerify")
);
const RegisterSuccessMain = lazy(() =>
  import("./components/landing_page/pages/Register/RegistrationPopupMsg")
);
const PaymentSuccessMain = lazy(() =>
  import("./pages/Transactions/paymentSuccess")
);
function App() {
  return (
    <div>
      {/* <MainLayout> */}
      {/* Disable Right Click */}
      <DisableRightClick />
      <Routes>
        <Route
          element={
            <Suspense fallback={<SpinnerComponent />}>
              <MainLayout />
            </Suspense>
          }
        >
          <Route element={<ProtectedRouteLayout />}>
            <Route
              element={
                <Suspense fallback={<SpinnerComponent />}>
                  <DragAndDrop />
                </Suspense>
              }
              path="/drag"
            />
            <Route
              element={
                <Suspense fallback={<SpinnerComponent />}>
                  <Dashboard />
                </Suspense>
              }
              path="/dashboard"
            />

            <Route
              element={
                <Suspense fallback={<SpinnerComponent />}>
                  <SupportsMain />
                </Suspense>
              }
              path="/support"
            />

            <Route
              element={
                <Suspense fallback={<SpinnerComponent />}>
                  <ResumeTemplatesMain />
                </Suspense>
              }
              path="/resume-templates"
            />

            <Route
              element={
                <Suspense fallback={<SpinnerComponent />}>
                  <PaymentListMain />
                </Suspense>
              }
              path="/payment-history"
            />
            <Route
              element={
                <Suspense fallback={<SpinnerComponent />}>
                  <SelectTemplate />
                </Suspense>
              }
              path="/templates"
            />

            <Route
              element={
                <Suspense fallback={<SpinnerComponent />}>
                  <PricingMain />
                </Suspense>
              }
              path="/pricing"
            />
            <Route
              element={
                <Suspense fallback={<SpinnerComponent />}>
                  <PaymentSuccessMain />
                </Suspense>
              }
              path="/payment-success"
            />
            <Route
              element={
                <Suspense fallback={<SpinnerComponent />}>
                  <PaymentDetailsMain />
                </Suspense>
              }
              path="/cart/payment-details"
            />
            <Route
              element={
                <Suspense fallback={<SpinnerComponent />}>
                  <SettingsMain />
                </Suspense>
              }
              path="/settings"
            />
            <Route
              element={
                <Suspense fallback={<SpinnerComponent />}>
                  <JobDashboardMain />
                </Suspense>
              }
              path="/jobs-dashboard"
            />
            <Route
              element={
                <Suspense fallback={<SpinnerComponent />}>
                  <AppliedJobs />
                </Suspense>
              }
              path="/jobs-applied"
            />
            <Route
              element={
                <Suspense fallback={<SpinnerComponent />}>
                  <ReferralsMain />
                </Suspense>
              }
              path="/referrals"
            />
            <Route
              element={
                <Suspense fallback={<SpinnerComponent />}>
                  <LogoutPage />
                </Suspense>
              }
              path="/logout"
            />

            {/* New */}

            <Route
              element={
                <Suspense fallback={<SpinnerComponent />}>
                  <AddTalentMain />
                </Suspense>
              }
              path="/new-talent"
            />

            <Route
              element={
                <Suspense fallback={<SpinnerComponent />}>
                  <EditTalentMain />
                </Suspense>
              }
              path="/edit-talent"
            />

            <Route
              element={
                <Suspense fallback={<SpinnerComponent />}>
                  <OtpVerification />
                </Suspense>
              }
              path="/otp-verification"
            />

            <Route
              element={
                <Suspense fallback={<SpinnerComponent />}>
                  <TalentListMain />
                </Suspense>
              }
              path="/talent-list"
            />
          </Route>
        </Route>
        {/* ********************** */}
        <Route element={<ProtectedRouteLayout />}>
          <Route
            element={
              <Suspense fallback={<SpinnerComponent />}>
                <ResumeBuilder />
              </Suspense>
            }
            path="/profile"
          />
          <Route
            element={
              <Suspense fallback={<SpinnerComponent />}>
                <ResumeBuilder />
              </Suspense>
            }
            path="/profile-update/:talentId"
          />
        </Route>

        <Route element={<UnprotectedRouteLayout />}>
          <Route
            element={
              <Suspense fallback={<SpinnerComponent />}>
                <Login />
              </Suspense>
            }
            path="/signin"
          />

          <Route
            element={
              <Suspense fallback={<SpinnerComponent />}>
                <MerchantLogin />
              </Suspense>
            }
            path="/merchant-signin"
          />

          <Route
            element={
              <Suspense fallback={<SpinnerComponent />}>
                <MerchantUpdatePassword />
              </Suspense>
            }
            path="/merchant-reset-password/"
          />

          <Route
            element={
              <Suspense fallback={<SpinnerComponent />}>
                <UpdatePassword />
              </Suspense>
            }
            path="/reset-password/"
          />
          <Route
            element={
              <Suspense fallback={<SpinnerComponent />}>
                <RegisterMain />
              </Suspense>
            }
            path="/signup"
          />

          <Route
            element={
              <Suspense fallback={<SpinnerComponent />}>
                <EmailVerification />
              </Suspense>
            }
            path="/email-verification"
          />
          <Route
            element={
              <Suspense fallback={<SpinnerComponent />}>
                <RegisterSuccessMain />
              </Suspense>
            }
            path="/registration-success"
          />
        </Route>
        <Route
          element={
            <Suspense fallback={<SpinnerComponent />}>
              <HomeMain />
            </Suspense>
          }
          path="/"
        />
        <Route path="*" element={<NotFound />} />
      </Routes>
      {/* </MainLayout> */}
    </div>
  );
}

export default App;
