import { createSlice } from "@reduxjs/toolkit";
import { getTalentsLsit, newTalent, emailVerification } from "../actions/merchant.actions";

export const marchantSlice = createSlice({
  name: "marchant",
  initialState: {
    talentList: [],
    loading: false,
    error: null,
  },
  reducers: {
    resetState: (state) => {
      return {
        talentList: [], 
        loading: false,
        error: null,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTalentsLsit.pending, (state, action) => {
        state.loading = true;
        state.talentList = [];
      })
      .addCase(getTalentsLsit.fulfilled, (state, action) => {
        state.loading = false;
        if (Array.isArray(action.payload?.data)) {
          state.talentList = action.payload?.data; 
        } else {
          state.talentList = [];  
        }

        state.error = "";
      })
      .addCase(getTalentsLsit.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });

    builder
      .addCase(newTalent.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(newTalent.fulfilled, (state, action) => {
        state.loading = false;
      
        if (action.payload?.status) {
          const newTalentData = action.payload?.data;
          state.talentList = [...newTalentData, ...state.talentList]; 
          state.error = ""; // Clear any error
        } else {
          state.error = action.payload?.message || "Failed to create new talent.";
        }
      })
      .addCase(newTalent.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });

      builder
      .addCase(emailVerification.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(emailVerification.fulfilled, (state, action) => {
        state.loading = false;
        
        if (action.payload?.status) {
          const newTalentData = action.payload?.data;  
          const talentIndex = state.talentList.findIndex(
            (x) => x.id === newTalentData.id
          );  
          if (talentIndex !== -1) { 
            state.talentList = state.talentList.map((talent) =>
              talent.id === newTalentData.id ? { ...talent, ...newTalentData } : talent
            ); 
          } else { 
            state.talentList = [...state.talentList, newTalentData];
          }
    
          state.error = "";
        } else {
          state.error = action.payload?.message || "Failed to create new talent.";
        }
      })
      .addCase(emailVerification.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { resetState } = marchantSlice.actions;
export default marchantSlice.reducer;
