import React, { useState } from "react";
import {
  Form,
  Input,
  Button,
  Card,
  Row,
  Col,
  notification,
  Breadcrumb,
  Typography,
} from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { OtpVerificationContainer } from "./styles";
import { useDispatch } from "react-redux";
import { emailVerification } from "../../redux/actions/merchant.actions";

const { Title, Text } = Typography;
const OtpVerification = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [otp, setOtp] = useState("");

  const { email } = location.state;

  const handleOtpSubmit = async () => {
    if (loading || otp.length !== 6 || isNaN(otp)) {
      notification.warning({
        message: "Invalid OTP",
        description: "Please enter a valid 6-digit numeric OTP.",
      });
      return;
    }
    setLoading(true);
    try {
      const data = {
        email: email,
        otp: otp,
      };

      const response = await dispatch(emailVerification(data));

      if (
        response &&
        response?.type == "marchant/emailVerification/fulfilled"
      ) {
        if (response?.payload?.status === true) {
          notification.success({
            message: "OTP Verified",
            description:
              response?.payload?.message ||
              "OTP has been successfully verified.",
          });
          navigate("/talent-list");
        } else {
          notification.warning({
            message: "OTP NOT Verified",
            description:
              response?.payload?.message ||
              "Please check the OTP and try again.",
          });
        }
      } else {
        notification.warning({
          message: "Verification Failed",
          description:
            response?.payload?.response?.data?.message ||
            "An error occurred while verifying the OTP.",
        });
      }
    } catch (error) {
      notification.error({
        message: "Error",
        description:
          "An error occurred while verifying the OTP. Please try again later.",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Breadcrumb
        style={{ margin: "16px 0", padding: "10px", fontWeight: 600 }}
      >
        <Breadcrumb.Item>
          Merchant / Talent / Email Verification
        </Breadcrumb.Item>
      </Breadcrumb>
      <OtpVerificationContainer>
        <Card className="otp-card">
          <Row justify="center">
            <Col span={24}>
              <Title level={3} className="otp-title">
                Verify Your Email
              </Title>
              <Text type="secondary">
                We have sent a 6-digit OTP to your email address:{" "}
                <strong>{email}</strong>
              </Text>
              <Form onFinish={handleOtpSubmit}  layout="vertical" className="otp-form">
                <Form.Item
                  label="Enter OTP *"
                  rules={[{ required: true, message: "Please enter the OTP" }]}
                  className="otp-form-item"
                >
                  <Input
                    className="otp-input"
                    value={otp}
                    onChange={(e) => setOtp(e.target.value)}
                    maxLength={6}
                    placeholder="6-digit OTP"
                    size="large"
                  />
                </Form.Item>
                <Form.Item style={{ textAlign: "center" }}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                    size="large"
                    className="verify-btn"
                  >
                    Verify OTP
                  </Button>
                </Form.Item>
              </Form>
              <Text type="secondary" className="resend-text">
                Didn't receive an OTP? <a href="#">Resend OTP</a>
              </Text>
            </Col>
          </Row>
        </Card>
      </OtpVerificationContainer>
    </>
  );
};

export default OtpVerification;
